<template>
  <div class="main-block">
    <div class="main-block__head">
      <h1 class="main-block__title">Фото документов *</h1>
    </div>
    <div class="add-img__block">
      <ImgBlock :data-img-list="dataImgList" :data-img="dataImg" :disabled-show="editDisabled" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      dataImgList: [],
      dataImg: {
        title: "Перетащите фотографии сюда",
        btn: "Выбрать фотографии",
        type: "image/png, image/gif, image/jpeg",
      },
      images: [],
      editDisabled: false,
    };
  },
  computed: {
    ...mapState(["driversById"]),
  },
  components: {
    ImgBlock: () => ({
      component: import("@/components/add/addImage"),
    }),
  },
  watch: {
    "$route.query"() {
      if (!this.$route.query.id) {
        this.editDisabled = false;
      }
    },
  },
  mounted() {
    if (this.$route.params.type === "new-driver" && this.$route.query.id) {
      this.dataImgList[0] = {
        path: this.driversById?.documents?.person_image,
      };
    }

    if (this.$route.query.id) {
      this.editDisabled = "disabled";
    }
  },
  async created() {
    if (this.$route.params.type === "new-driver" && this.$route.query.id) {
      this.dataImgList[0] = {
        path: this.driversById?.documents?.person_image,
      };
    }
  },
};
</script>

<style scoped>
.add-img__block {
  align-items: flex-start;
  margin-top: 15px;
}
</style>
